import React from "react"
import { Carousel, Col, Container, Row } from "react-bootstrap"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import FullWidthLayout from "../components/full_width_layout"

const X = (data) => {
    return <FullWidthLayout pageInfo={{ pageName: "commercial", pageTitle: "IN DESIGN" }}>
    <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
    <Container fluid>
        <Row>
          <Col xs={{order: 2, span: 12}} md={{order: 1, span: 8}}>
            <StaticImage src="../data/commercial/projects/Brickworks/IMG_9837.jpg" alt="" className="mt-3" />
          </Col>
          <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}  xs={{order: 1, span: 12}} md={{order:2, span: 4}}>
            <h1 className="mt-3" style={{width: '100%'}}>2020 Frog Song</h1>
            <p className="text-justify">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Integer a libero ac ante tempus interdum. Donec non purus. 
              Donec sed dolor. Suspendisse faucibus Aenean a nisl non ante</p>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
              <StaticImage src="../data/commercial/projects/Brickworks/IMG_9837.jpg" alt="" />              
		      </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
              <StaticImage src="../data/commercial/projects/Brickworks/IMG_9849.jpg" alt="" />
              <Carousel className="mt-3">
                <Carousel.Item>
                  <StaticImage src="../data/commercial/projects/David_L_Waldron/IMG_9819.jpg" alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <StaticImage src="../data/commercial/projects/Memorial Park/DJI_0138.jpg" alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <StaticImage src="../data/commercial/projects/Memorial Park/IMG_1268.jpg" alt="" />
                </Carousel.Item>
              </Carousel>
              <StaticImage className="mt-3" src="../data/commercial/projects/Memorial Park/DJI_0103.jpg" alt="" />
              <StaticImage className="mt-3" src="../data/commercial/projects/Memorial Park/IMG_1247.jpg" alt="" />            
		      </Col>
        </Row>
    </Container>
</FullWidthLayout>
}
/*
export const Data = graphql`
  query{
    allFile(filter: {sourceInstanceName: {eq: "home_page"}}, sort: {fields: [name], order: ASC}) {
      nodes {
        id
        name
        relativePath
        sourceInstanceName
        relativeDirectory
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
  }
`*/


export default X